import React from "react";
import classNames from "classnames";
import * as styles from "./_Industries.module.scss";
import { LearnMoreButtonV4 } from "@components/Button_new/Button";
import { TabsV3, TypographyV2 } from "@components";
import { highlightedText } from "@components/TypographyV2/TypographyV2";
import {
	BiopharmaceuticalRD,
	BiopharmaceuticalRD2,
	IndustrialBiotech,
	IndustrialBiotech2,
	Biorepository,
	Biorepository2,
	ClinicalResearch,
	ClinicalResearch2,
	Biomanufacturing,
	Biomanufacturing2,
	ContractServices,
	ContractServices2,
} from "@assets/images/landing_page/index";
import { useWindowSizeType, mapToKeyValue } from "@helpers/Hooks";

const TAB_CONTENT = [
	{
		title: "Biopharmaceutical",
		subtitle: highlightedText`Integrated solutions for ${"biopharma"} R&D decision-making`,
		content:
			"Embrace an efficient R&D solution tailored specifically for biopharma. Our platform seamlessly integrates swift lead identification with informed decision-making, bolstered by tools that foster team collaboration. It's an elevated approach to R&D, blending practicality with actionable insights.",
		image: <BiopharmaceuticalRD />,
		image2: <BiopharmaceuticalRD2 />,
		badges: ["Drug Discovery & Development", "Therapeutic R&D"],
		to: "BIOPHARMA",
		buttonText: "For innovations",
	},
	{
		title: "Industrial biotech",
		subtitle: highlightedText`Unified R&D toolkit for ${"industrial biotech"}`,
		content:
			"Navigate the bioeconomy frontier with a comprehensive R&D toolkit. Our platform seamlessly fuses strain design with process upscaling, underpinned by holistic sample traceability on a unified digital landscape. It's a forward-thinking approach to R&D, merging inventory management with integrated lab instrument connectivity for data precision and traceability.",
		image: <IndustrialBiotech />,
		image2: <IndustrialBiotech2 />,
		badges: ["Bio-Based Innovations", "Bio-economy Advancements"],
		to: "BIOTECH",
		buttonText: "Scale your biotech",
	},
	{
		title: "Biorepository",
		subtitle: highlightedText`Streamlined ${"biorepository"} efficiency`,
		content:
			"Delve into advanced biorepository management with our intuitive platform. Seamlessly visualize your research space, enhanced by Genemod's Consumable space feature, ensuring swift location and tracking of every specimen. Our approach harmonizes automated experiment flow with integrated data tools, propelling you towards efficient collaboration within and beyond your lab's boundaries.",
		image: <Biorepository />,
		image2: <Biorepository2 />,
		badges: ["Specimen Management & Tracking", "Advanced Sample Storage"],
		to: "REPOSITORY",
		buttonText: "Optimize process",
	},
	{
		title: "Clinical research",
		subtitle: highlightedText`Innovative ${"clinical research"} solutions for data-driven analytics`,
		content:
			"Elevate clinical research with Genemod’s LIMS software, designed to manage and analyze complex clinical trial data with unparalleled precision. Our platform streamlines the entire clinical research process, from patient enrollment to data collection and analysis. By integrating advanced analytics, Genemod’s LIMS software accelerates the discovery of insights, fostering faster decision-making and more effective trial outcomes.",
		image: <ClinicalResearch />,
		image2: <ClinicalResearch2 />,
		badges: ["Clinical Trial Innovation", "Biomedical Research"],
		to: "RESEARCH",
		buttonText: "Streamline research",
	},
	{
		title: "Biomanufacturing",
		subtitle: highlightedText`Revolutionizing ${"biomanufacturing"} with precision and scalability`,
		content:
			"Genemod’s LIMS software is tailored to meet the unique demands of biomanufacturing. It offers comprehensive tools for managing bioprocess workflows, tracking batch production, and ensuring quality control. The software facilitates the efficient handling of large-scale biological data, essential in biomanufacturing operations, and ensures adherence to industry regulations and standards.",
		image: <Biomanufacturing />,
		image2: <Biomanufacturing2 />,
		badges: ["Bioprocess Optimization", "Pharmaceutical Production"],
		to: "MANUFACTURING",
		buttonText: "Enhance quality",
	},
	{
		title: "Contract services",
		subtitle: highlightedText`Advancing ${"contract services"} with strategic operational insights`,
		content:
			"Optimize contract service management with Genemod’s LIMS and ELN software, engineered to enhance operational efficiency and client satisfaction. It provides a centralized platform for managing research samples, tracking project progress, and maintaining comprehensive documentation.",
		image: <ContractServices />,
		image2: <ContractServices2 />,
		badges: ["CRO & CDMO", "Pharma Collaborations"],
		to: "SERVICES",
		buttonText: "Custom solutions",
	},
] as const;
const INDUSTRIES_TABS = TAB_CONTENT.map((tab) => tab.title);
type IndustriesTabs = typeof TAB_CONTENT[number]["title"];

export const Industries = () => {
	const windowSizeType = useWindowSizeType();
	const [selectedTab, setSelectedTab] =
		React.useState<IndustriesTabs>("Biopharmaceutical");

	const { title, subtitle, content, image, image2, badges, to, buttonText } =
		mapToKeyValue(TAB_CONTENT, "title")[selectedTab];

	const tabsComp = (
		<TabsV3
			tabs={INDUSTRIES_TABS}
			selectedTab={selectedTab}
			onChange={setSelectedTab}
			wrapperClass={styles.tabs}
			tabsClassName={styles.tabElements}
		/>
	);

	return (
		<div className={styles.industriesContainer}>
			<div className={styles.industries}>
				<div className={styles.tabAndContent}>
					{tabsComp}
					<div className={styles.contentImageContainer}>
						<div className={styles.imagesContainer}>
							<div className={styles.imagesCard}>
								<div
									className={classNames(
										styles.firstImage,
										styles.image
									)}
								>
									<div className={styles.orangeBorder}></div>
									{image2}
								</div>
								<div
									className={classNames(
										styles.secondImage,
										styles.image
									)}
								>
									{image}
									<div className={styles.orangeBorder}></div>
								</div>
							</div>
						</div>
						<div className={styles.titleAndContentContainer}>
							<div className={styles.titleAndContent}>
								<TypographyV2
									variant="HEADING_4"
									tabletVariant="HEADING_4"
									mobileVariant="HEADING_4"
									weight="MEDIUM"
									color="brand-500"
									className={styles.title}
								>
									{subtitle}
								</TypographyV2>
								<TypographyV2
									variant="BODY_TEXT_LARGE"
									tabletVariant="BODY_TEXT_LARGE"
									mobileVariant="BODY_TEXT_LARGE"
									weight="REGULAR"
									color="text-tertiary-v2"
									className={styles.content}
								>
									{content}
								</TypographyV2>
								<div
									className={styles.badgeAndLearnMoreSection}
								>
									<div className={styles.badges}>
										{badges.map((badge, index) => {
											return (
												<div
													className={styles.badge}
													key={index}
												>
													<TypographyV2
														color="text-tertiary-v2"
														variant="BODY_TEXT_MEDIUM"
														tabletVariant="BODY_TEXT_MEDIUM"
														mobileVariant="BODY_TEXT_MEDIUM"
														weight="REGULAR"
														style={{ margin: 0 }}
													>
														#{badge}
													</TypographyV2>
												</div>
											);
										})}
									</div>
									<div className={styles.learnMoreButton}>
										<LearnMoreButtonV4
											to={to}
											textClassName={
												styles.learnMoreButtonText
											}
											className={
												styles.learnMoreButtonContainer
											}
										>
											{" "}
											{buttonText}{" "}
										</LearnMoreButtonV4>

										{/* <LearnMoreButtonV4
											to={to}
											textClassName={
												styles.learnMoreButtonText
											}
											className={
												styles.learnMoreButtonContainer
											}
										> {buttonText} </ LearnMoreButtonV4> */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
